import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PageHeader from "../components/Global/PageHeader";

const SketchViewer = ({ data }) => (
  <div className='bg-neutral-50 text-neutral-700'>
    <Layout>
      {data && (
        <div className='relative'>
          <div className='bg-gradient-to-b from-cadt via-cadt to-neutral-100/10 md:h-56 h-72 absolute top-0 w-full'></div>
          <PageHeader
            pageTitle={data.privacyPolicy.frontmatter.title}
            backButton={false}
          />

          <div className='2xl:px-40 container px-10 py-20 mx-auto'>
            <div
              className='max-w-none prose'
              dangerouslySetInnerHTML={{ __html: data.privacyPolicy.html }}
            ></div>
          </div>
        </div>
      )}
    </Layout>
  </div>
);

export default SketchViewer;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    privacyPolicy: markdownRemark(
      frontmatter: { language: { eq: $language } }
      fileAbsolutePath: { regex: "/content/privacy-policy/" }
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`;
export const Head = ({ data }) => {
  const title = JSON.parse(
    data.locales.edges[0].node.data
  ).TR_INFO_PRIVACY_POLICY;

  return (
    <>
      <title>{title} | CADT</title>
    </>
  );
};
